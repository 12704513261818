import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import getYoutubeID from 'get-youtube-id';
import { RiPlayFill } from 'react-icons/ri';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import arrow from '../../images/videoArrow.svg';
import arrowWhite from '../../images/videoArrow_white.svg';
import { getVideoTypeByUrl } from '../../utils/projectUtils';
import { Override } from '../../utils/typescript';
import { clsx, withDataLayer } from '../../utils/utils';
import ImageWithFallbacks from './ImageWithFallbacks';
import * as styles from './Video.module.scss';

export type VideoProps = Override<
  ReactPlayerProps,
  {
    url: string;
    className?: string;
    isAVerticalVideo?: boolean;
    iconOnTopLeftCorner?: boolean;
    fitContent?: boolean;
    videoTitle?: string;
    videoSubtitle?: string;
  }
>;

/**
 * Used to get the thumbnail for the video, only tries to handle cases where
 * react-player autofetching is not good enough.
 * Can return either a url, a ReactElement, or null if we don't want to handle it specially.
 */
function useVideoThumbnail(url: string): string | React.ReactElement | false | true {
  const [thumbnail, setThumbnail] = useState<string | React.ReactElement | false | true>(false);

  if (thumbnail) {
    return thumbnail;
  }

  if (
    url.match(
      /^(https?:\/\/)?(((www\.)?youtube\.com\/(watch\?v=|embed\/|shorts\/))|(youtu\.be\/))[\w-]+$/,
    )
  ) {
    // YOUTUBE
    const youtubeId = getYoutubeID(url);
    if (!youtubeId) {
      throw new Error('Could not extract youtube id from url: ' + url);
    }
    const largeImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/maxresdefault.jpg';
    const mediumImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/sddefault.jpg';
    const smallImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/hqdefault.jpg';

    setThumbnail(
      <ImageWithFallbacks
        className={clsx(styles.youtubeThumbnail, styles.thumbnail)}
        src={largeImgUrl}
        fallbackImage={[mediumImgUrl, smallImgUrl]}
        alt="thumbnail"
        getConsiderSuccessAsError={e => {
          if (e.path && e.path[0] && (e.path[0].width !== 120 || e.path[0].height !== 90)) {
            return false;
          }
          if (e.target && (e.target.width !== 120 || e.target.height !== 90)) {
            return false;
          }
          return true;
        }}
      ></ImageWithFallbacks>,
    );
  } else if (url.includes('wistia')) {
    // WISTIA
    const wistiaUrlParts = url.split('/');
    const wistiaId = wistiaUrlParts[wistiaUrlParts.length - 1];
    (async () => {
      try {
        const resp = await fetch(
          `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaId}`,
        );
        if (resp.status === 200) {
          const data = await resp.json();
          setThumbnail(data.thumbnail_url);
        } else {
          throw new Error(
            `Got status code ${resp.status} when trying to fetch thumbnail for wistia video ${url}`,
          );
        }
      } catch (err) {
        Sentry.captureException(err);
        // fallback to autofetch, though it won't work
        setThumbnail(true);
      }
    })();
  } else {
    // true means autofetch, used because we don't have a
    // special fetching logic for the type of video used
    setThumbnail(true);
  }

  return thumbnail;
}

function Video(props: VideoProps): React.ReactElement {
  const thumbnail = useVideoThumbnail(props.url);

  const type = getVideoTypeByUrl(props.url);

  const isAVerticalVideo = !!(
    props.isAVerticalVideo ||
    props.url.match(/\/shorts\//) ||
    type === 'tiktok' ||
    type === 'instagram'
  );

  const [hasVideoStartedPlaying, setHasVideoStartedPlaying] = useState(false);

  return (
    <div className={styles.videoWrapper}>
      <div
        className={clsx(
          styles.container,
          props.className,
          isAVerticalVideo && styles.verticalVideo,
          props.fitContent && styles.fitContent,
        )}
      >
        {type === 'tiktok' && (
          <div className={clsx(styles.embed, styles.tiktok)}>
            <TikTokEmbed url={props.url} />
          </div>
        )}
        {type === 'instagram' && (
          <div className={clsx(styles.embed, styles.instagram)}>
            <InstagramEmbed url={props.url} />
          </div>
        )}
        {type !== 'tiktok' && type !== 'instagram' && (
          <ReactPlayer
            light={
              typeof thumbnail === 'string' ? (
                <img src={thumbnail} className={styles.thumbnail} alt="thumbnail"></img>
              ) : (
                thumbnail
              )
            }
            playing
            controls
            width="100%"
            height={isAVerticalVideo ? '100%' : 'auto'}
            style={{
              aspectRatio: isAVerticalVideo ? '9/16' : '16/9',
              background: 'black',
              overflow: 'hidden',
            }}
            playIcon={
              <>
                <div className={styles.overlay}></div>
                <div
                  className={clsx(
                    styles.iconWrapper,
                    props.iconOnTopLeftCorner && styles.iconOnTopLeftCorner,
                  )}
                >
                  <RiPlayFill className={styles.icon} />
                </div>
              </>
            }
            {...props}
            onStart={() => {
              setHasVideoStartedPlaying(true);
              withDataLayer(dataLayer => {
                dataLayer.push({ event: 'video-start', videoUrl: props.url });
              });

              if (props.onStart) {
                props.onStart();
              }
            }}
          />
        )}
      </div>
      {!hasVideoStartedPlaying && (props.videoTitle || props.videoSubtitle) && (
        <div className={styles.videoSubtitleAndArrowWrapper}>
          <img src={arrow} className={clsx(styles.arrow, styles.mobile)} alt="" />
          <div className={styles.videoSubtitleContainer}>
            {props.videoTitle && <strong className={styles.videoTitle}>{props.videoTitle}</strong>}
            {props.videoSubtitle && <p className={styles.videoSubtitle}>{props.videoSubtitle}</p>}
            <img src={arrowWhite} className={clsx(styles.arrow, styles.desktop)} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Video;
