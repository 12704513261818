// extracted by mini-css-extract-plugin
export var arrow = "Video-module--arrow--a9220";
export var container = "Video-module--container--125a8";
export var desktop = "Video-module--desktop--b7a0c";
export var fitContent = "Video-module--fitContent--4ba58";
export var icon = "Video-module--icon--41140";
export var iconOnTopLeftCorner = "Video-module--iconOnTopLeftCorner--b5509";
export var iconWrapper = "Video-module--iconWrapper--063b3";
export var imageContainer = "Video-module--imageContainer--194e5";
export var mobile = "Video-module--mobile--a6b72";
export var overlay = "Video-module--overlay--0d36c";
export var textStyleLargeTitle = "Video-module--text-style-large-title--26a45";
export var textStyleLargestTitle = "Video-module--text-style-largest-title--9f4c0";
export var textStyleMediumTitle = "Video-module--text-style-medium-title--74f69";
export var textStyleSmallTitle = "Video-module--text-style-small-title--1d1be";
export var thumbnail = "Video-module--thumbnail--8931c";
export var verticalVideo = "Video-module--verticalVideo--2bf50";
export var videoSubtitle = "Video-module--videoSubtitle--8ac59";
export var videoSubtitleAndArrowWrapper = "Video-module--videoSubtitleAndArrowWrapper--0a73f";
export var videoSubtitleContainer = "Video-module--videoSubtitleContainer--d1928";
export var videoTitle = "Video-module--videoTitle--df64b";
export var videoWrapper = "Video-module--videoWrapper--650c9";
export var youtubeThumbnail = "Video-module--youtubeThumbnail--e3526";