import React, { useRef } from 'react';

import { Widget } from '@typeform/embed-react';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import { clsx, withDataLayer } from '../../utils/utils';
import ButtonLink from '../ui/ButtonLink';
import * as styles from './CtaSection.module.scss';
export interface CtaSectionProps {
  title: string;
  text: string;
  url: string;
  withTypeform?: boolean;
  typeformId?: string;
  className?: string;
}

const CtaSection = ({
  title,
  text,
  url,
  withTypeform,
  typeformId,
  className,
}: CtaSectionProps): React.ReactElement => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 120%', 'start 0%'],
  });

  const backgroundTranslateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], {
    ease: easeOut,
  });
  const contentTranslateYMV = useTransform(scrollYProgress, [0, 1], ['-15vh', '0vh'], {
    ease: easeOut,
  });

  return (
    <div
      ref={sectionRef}
      className={clsx(styles.container, withTypeform && styles.withForm, className)}
    >
      <motion.div className={styles.background} style={{ y: backgroundTranslateYMV }}></motion.div>
      <motion.div className={styles.content} style={{ y: contentTranslateYMV }}>
        <div className={styles.textContainer} id="cta-section">
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
          {!withTypeform && (
            <ButtonLink
              onClick={() => {
                withDataLayer(dataLayer => {
                  dataLayer.push({ event: 'cta-button-click', context: 'cta-section' });
                });
              }}
              to={url}
              className={styles.button}
            >
              Schedule a call now
            </ButtonLink>
          )}
        </div>
        <div className={styles.formContainer}>
          {withTypeform && typeformId && (
            <Widget inlineOnMobile id={typeformId} className={styles.form} />
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default CtaSection;
