// extracted by mini-css-extract-plugin
export var afterContainer = "CaseStudiesSection-module--afterContainer--e86bd";
export var afterResultsText = "CaseStudiesSection-module--afterResultsText--0206a";
export var arrow = "CaseStudiesSection-module--arrow--2a274";
export var beforeAndAfterContainer = "CaseStudiesSection-module--beforeAndAfterContainer--38487";
export var beforeContainer = "CaseStudiesSection-module--beforeContainer--a897e";
export var button = "CaseStudiesSection-module--button--e8106";
export var byTheNumbersContainer = "CaseStudiesSection-module--byTheNumbersContainer--4a76a";
export var byTheNumbersText = "CaseStudiesSection-module--byTheNumbersText--1d091";
export var byTheNumbersTitle = "CaseStudiesSection-module--byTheNumbersTitle--aa5eb";
export var caseStudiesContainer = "CaseStudiesSection-module--caseStudiesContainer--d4c74";
export var caseStudyContainer = "CaseStudiesSection-module--caseStudyContainer--fd6a7";
export var caseStudySpan = "CaseStudiesSection-module--caseStudySpan--27492";
export var container = "CaseStudiesSection-module--container--ce2cd";
export var contentContainer = "CaseStudiesSection-module--contentContainer--b099b";
export var descriptionContainer = "CaseStudiesSection-module--descriptionContainer--1cbdc";
export var imageContainer = "CaseStudiesSection-module--imageContainer--25eb5";
export var itemTitle = "CaseStudiesSection-module--itemTitle--bba6d";
export var line = "CaseStudiesSection-module--line--28452";
export var modalDivider = "CaseStudiesSection-module--modalDivider--6569a";
export var modalSectionDivider = "CaseStudiesSection-module--modalSectionDivider--17876";
export var modalTitle = "CaseStudiesSection-module--modalTitle--3659f";
export var modalTitleDivider = "CaseStudiesSection-module--modalTitleDivider--da43f";
export var name = "CaseStudiesSection-module--name--88edf";
export var nameDivider = "CaseStudiesSection-module--nameDivider--3c972";
export var results = "CaseStudiesSection-module--results--81318";
export var tableImageContainer = "CaseStudiesSection-module--tableImageContainer--7d966";
export var tableTitle = "CaseStudiesSection-module--tableTitle--5487b";
export var textRowContainer = "CaseStudiesSection-module--textRowContainer--2f873";
export var textStyleLargeTitle = "CaseStudiesSection-module--text-style-large-title--790c2";
export var textStyleLargestTitle = "CaseStudiesSection-module--text-style-largest-title--d0d26";
export var textStyleMediumTitle = "CaseStudiesSection-module--text-style-medium-title--e62d3";
export var textStyleSmallTitle = "CaseStudiesSection-module--text-style-small-title--813d2";