import { VIDEO_TYPES_MAP } from '../constants';

export function getVideoTypeByUrl(url: string): keyof typeof VIDEO_TYPES_MAP {
  const type = Object.values(VIDEO_TYPES_MAP).find(({ formatRegex }) =>
    url.trim().match(formatRegex),
  )?.type;
  if (!type) {
    throw new Error(
      `Got video url "${url}" that didn't match any known type: ${Object.keys(VIDEO_TYPES_MAP).join(
        ', ',
      )}`,
    );
  }
  return type;
}
