import React, { useEffect, useRef } from 'react';
import usePortal from 'react-useportal';

import { clsx } from '../../utils/utils';
import Modal from './Modal';
import * as styles from './ModalOverlay.module.scss';

export interface ModalOverlayProps {
  open: boolean;
  className?: string;
  onClose: React.MouseEventHandler;
  children: React.ReactNode;
}

const ModalOverlay = ({
  open,
  className,
  children,
  onClose,
}: ModalOverlayProps): React.ReactElement => {
  const { Portal } = usePortal();
  const rootRef = useRef<HTMLDivElement>(null);

  const skipNextOverlayClick = useRef(false);

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'scroll';

      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.scrollTo(0, 0);
        }
      }, 500);
    }
  }, [open]);

  return (
    <Portal>
      <div
        ref={rootRef}
        className={clsx(styles.overlay, open && styles.open, className)}
        onClick={e => {
          e.stopPropagation();
          if (skipNextOverlayClick.current) {
            skipNextOverlayClick.current = false;
          } else {
            onClose(e);
          }
        }}
      >
        <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
          <Modal
            onClose={onClose}
            onMouseDown={() => (skipNextOverlayClick.current = true)}
            onMouseUp={() => (skipNextOverlayClick.current = false)}
          >
            {children}
          </Modal>
          <div className={styles.modalDecenterSpace} onClick={onClose}></div>
        </div>
      </div>
    </Portal>
  );
};

export default ModalOverlay;
