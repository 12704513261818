import BlockContent from '@sanity/block-content-to-react';
import { useInView } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { CaseStudy } from '../../graphql-fragments/CaseStudy';
import serializers from '../../serializers';
import { withDataLayer } from '../../utils/utils';
import Image from '../ui/Image';
import ModalOverlay from '../ui/ModalOverlay';
import ModuleLayout from '../ui/ModuleLayout';
import * as styles from './CaseStudiesSection.module.scss';

export interface CaseStudiesSectionProps {
  caseStudies: Array<CaseStudy>;
}

const CaseStudiesSection = ({ caseStudies }: CaseStudiesSectionProps): React.ReactElement => {
  const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <ModuleLayout className={styles.container} title={'Case Studies'}>
      <div className={styles.caseStudiesContainer} ref={ref}>
        {caseStudies.map((caseStudy, i) => (
          <div
            className={styles.caseStudyContainer}
            key={i}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(11, 33, 56, 0) 28.65%, #0B2138 85.42%), url(${caseStudy.image.asset.url})`,
              transform: isInView ? 'none' : `translateX(100vw)`,
              opacity: isInView ? 1 : 0,
              transition: `transform 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), opacity 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), background-size 0.3s ease`,
            }}
            onClick={() => {
              setModalOpenIndex(i);
              withDataLayer(dataLayer => {
                dataLayer.push({ event: 'case-study-open', caseStudyTitle: caseStudy.title });
              });
            }}
          >
            <div className={styles.caseStudySpan}>
              <div className={styles.line}></div>
              <span className={styles.caseStudySpan}> Case study</span>
            </div>
            <h3 className={styles.name}>{caseStudy.title}</h3>
            <div className={styles.nameDivider}></div>
            <BlockContent
              className={styles.results}
              blocks={caseStudy._rawResults.slice(0, 1)}
              renderContainerOnSingleChild
              serializers={serializers}
            />
            <button className={styles.button}>
              View case <FaArrowRight className={styles.arrow} />
            </button>
            <ModalOverlay onClose={() => setModalOpenIndex(null)} open={i === modalOpenIndex}>
              <span className={styles.modalTitle}>{caseStudy.title}</span>
              <div className={styles.modalTitleDivider}></div>
              <div className={styles.modalSectionDivider}></div>
              <div className={styles.textRowContainer}>
                <span className={styles.itemTitle}>Solution</span>
                <BlockContent
                  className={styles.modalText}
                  blocks={caseStudy._rawSolution}
                  renderContainerOnSingleChild
                  serializers={serializers}
                />
              </div>
              <div className={styles.textRowContainer}>
                <span className={styles.itemTitle}>Results</span>
                <BlockContent
                  className={styles.modalText}
                  blocks={caseStudy._rawResults}
                  renderContainerOnSingleChild
                  serializers={serializers}
                />
              </div>
              <div className={styles.modalDivider}></div>
              <div className={styles.descriptionContainer}>
                <div className={styles.contentContainer}>
                  <div className={styles.imageContainer}>
                    <Image image={caseStudy.image} />
                  </div>
                  <BlockContent
                    className={styles.modalText}
                    blocks={caseStudy._rawDescription}
                    renderContainerOnSingleChild
                    serializers={serializers}
                  />
                </div>
                <div className={styles.modalDivider}></div>
              </div>
              <div className={styles.beforeAndAfterContainer}>
                <div className={styles.contentContainer}>
                  <div className={styles.beforeContainer}>
                    <span className={styles.tableTitle}>Before</span>
                    <div className={styles.tableImageContainer}>
                      <Image image={caseStudy.beforeImage} />
                    </div>
                  </div>
                  <div className={styles.afterContainer}>
                    <span className={styles.tableTitle}>After</span>
                    <div className={styles.tableImageContainer}>
                      <Image image={caseStudy.afterImage} />
                    </div>
                  </div>
                </div>
              </div>
              <BlockContent
                className={styles.afterResultsText}
                blocks={caseStudy._rawResultsText}
                renderContainerOnSingleChild
                serializers={serializers}
              />
              <div className={styles.byTheNumbersContainer}>
                <h4 className={styles.byTheNumbersTitle}>{caseStudy.byTheNumbers.title}</h4>
                <BlockContent
                  className={styles.byTheNumbersText}
                  blocks={caseStudy.byTheNumbers._rawText}
                  renderContainerOnSingleChild
                  serializers={serializers}
                />
              </div>
            </ModalOverlay>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default CaseStudiesSection;
